<script setup lang="ts">
import Container from '@/components/blocks/Container.vue'

defineProps<{
  fields: {
    title: VoixTextFieldInterface
    image: VoixImageFieldInterface
  }
}>()
defineSlice({
  name: {
    label: 'Included with Stay',
    group: 'blocks',
  },
  preview: 'SlicesBlocksIncludedWithStay.jpg',
  fields: {
    title: {
      type: 'text',
      label: 'Title',
      name: 'title',
    },
    image: {
      type: 'media',
      label: 'Image',
      breakpoints: {
        default: { width: 470, height: 300 },
      },
      name: 'image',
    },
  },
  slots: [],
})

const isVisible = ref(false)
function onVisibilityChange(entry: { intersectionRatio: number }) {
  if (entry.intersectionRatio * 10 > 1)
    isVisible.value = true
  else
    isVisible.value = false
}
</script>

<template>
  <Container v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }" class="max-w-[1600px]">
    <div class="grid lg:grid-cols-2">
      <div
        class="duration-1000 ease-out" :class="{
          '-translate-x-full opacity-0': !isVisible,
          'translate-x-0 opacity-100': isVisible,
        }"
      >
        <VoixMedia :field="fields.image" />
      </div>
      <div
        class="flex flex-col justify-center space-y-4 pt-10 lg:pt-0 pl-4 lg:pl-8  pr-4 lg:pr-0 duration-1000 ease-out delay-500" :class="{
          'opacity-0': !isVisible,
          'opacity-100': isVisible,
        }"
      >
        <h4 class="text-center lg:text-left">
          {{ fields.title.value }}
        </h4>
        <div class="grid grid-cols-2 md:grid-cols-3 gap-5 text-base sm:text-sm font-sandpiper-display">
          <div class="flex justify-start items-center space-x-4 ">
            <IconsDining class="w-8 h-8 flex-none" />
            <span>{{ $t('unlimited-dining') }}</span>
          </div>
          <div class="flex justify-start items-center space-x-4 ">
            <IconsDrinks class="w-8 h-8 flex-none" />
            <span>{{ $t('unlimited-drinks') }}</span>
          </div>
          <div class="flex justify-center sm:justify-start items-center space-x-4 ">
            <IconsPool class="w-8 h-8 flex-none text-gray-800" :color="false" />
            <span>{{ $t('pools') }}</span>
          </div>
          <div class="flex justify-center sm:justify-start items-center space-x-4 ">
            <IconsWifi class="w-8 h-8 flex-none text-gray-800" :color="false" />
            <span>Wi-Fi</span>
          </div>
          <div class="flex justify-center sm:justify-start items-center space-x-4 ">
            <IconsFitnessCenter class="w-8 h-8 flex-none text-gray-800" :color="false" />
            <span>{{ $t('fitness-center') }}</span>
          </div>
          <div class="flex justify-center sm:justify-start items-center space-x-4 ">
            <IconsAccessibility class="w-8 h-8 flex-none text-gray-800" :color="false" />
            <span>{{ $t('accessibility') }}</span>
          </div>
          <div class="flex justify-start items-center space-x-4 ">
            <IconsSun class="w-8 h-8 flex-none" />
            <span>{{ $t('outdoor-activities') }}</span>
          </div>
          <div class="flex justify-start items-center space-x-4 ">
            <IconsSurf class="w-8 h-8 flex-none" />
            <span>{{ $t('non-motorized-water-sports') }}</span>
          </div>
          <div class="flex justify-start items-center space-x-4 ">
            <IconsStar class="w-8 h-8 flex-none" />
            <span>{{ $t('so-much-more') }}</span>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>
